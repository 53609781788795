import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HeaderMain from "../header-main/header-main"
import HomeHeroImage from "../home-hero-image/home-hero-image"
import PropTypes from "prop-types"

import {
  heroUnit,
  heroUnitInner,
  heroUnitContent,
  heroUnitImage,
} from "./hero-unit.module.scss"

const getImage = graphql`
  query {
    serviceImage: file(relativePath: { eq: "branding.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const HeroUnit = ({ pageTitle, heroImage, children, ...props }) => {
  const { serviceImage } = useStaticQuery(getImage)

  return (
    <div
      data-testid="hero-unit"
      className={`${heroUnit} ${props.class ? props.class : ""}`}
    >
      <HeaderMain />
      <div className={heroUnitInner}>
        <div className={heroUnitContent}>
          <h1>{pageTitle}</h1>
          {children}
        </div>
        <div className={heroUnitImage}>
          <HomeHeroImage heroImage={heroImage ? heroImage : serviceImage} />
        </div>
      </div>
    </div>
  )
}

const propTypes = {
  children: PropTypes.node,
  heroImage: PropTypes.object,
  pageTitle: PropTypes.string.isRequired,
}

HeroUnit.propTypes = propTypes

export default HeroUnit
