import React from "react"
import Img from "gatsby-image"

const HomeHeroImage = ({ heroImage }) => {
  if (heroImage) {
    return <Img fluid={heroImage.childImageSharp.fluid} />
  } else {
    return <p>No Image</p>;
  }
}

export default HomeHeroImage
