import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import SeoHeaders from "../components/seo-headers/seo-headers"
import LayoutMain from "../components/layout-main/layout-main"
import HeroUnit from "../components/hero-unit/hero-unit"
import FormContact from "../components/form-contact/form-contact"

import "./contact-us.scss"

const getImages = graphql`
  query {
    contactImage: file(relativePath: { eq: "branding.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ContactPage = () => {
  const { contactImage } = useStaticQuery(getImages)

  return (
    <LayoutMain className="page page-contact">
      <SeoHeaders title="Contact" keywords={[`contact us`]} />
      <HeroUnit pageTitle="Contact Us" />
      <section>
        <div className="content-section">
          <div className="page-content">
            <div className="contact-image">
              {contactImage && (
                <Img fluid={contactImage.childImageSharp.fluid} />
              )}
            </div>
            <div className="form-holder">
              <h3 className="subheading">Get in Touch</h3>
              <h2 className="main-heading">
                Consult Our Experts About Your Project
              </h2>
              <FormContact />
            </div>
          </div>
        </div>
      </section>
    </LayoutMain>
  )
}

export default ContactPage
